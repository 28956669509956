<template>
  <div>
    <h3></h3>
    <div class="row mb-1">
      <div class="col-md-6 mb-2 mb-md-0">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-outline-secondary"
            :class="[onlineFilter === '' && 'active']"
            @click.prevent="onlineFilter = ''"
          >
            <span class="badge bg-white text-secondary">{{
              users.length
            }}</span>
            Tous
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            :class="[onlineFilter === 'Active' && 'active']"
            @click.prevent="onlineFilter = 'Active'"
          >
            <span class="badge bg-success text-white">{{
              filterList(users, { onlineStatus: "Active" }).length
            }}</span>
            Payés
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            :class="[onlineFilter === 'Away' && 'active']"
            @click.prevent="onlineFilter = 'Away'"
          >
            <span class="badge bg-warning text-white">{{
              filterList(users, { onlineStatus: "Away" }).length
            }}</span>
            Impayés
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            :class="[onlineFilter === 'Do not disturb' && 'active']"
            @click.prevent="onlineFilter = 'Do not disturb'"
          >
            <span class="badge bg-danger text-white">{{
              filterList(users, { onlineStatus: "Do not disturb" }).length
            }}</span>
            Rejetés
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            :class="[onlineFilter === 'Invisible' && 'active']"
            @click.prevent="onlineFilter = 'Invisible'"
          >
            <span class="badge bg-secondary text-white">{{
              filterList(users, { onlineStatus: "Invisible" }).length
            }}</span>
          Brouillon
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-primary float-right"
          @click="showNewInvoiceModalClick"
        >
          <i class="fas fa-plus"></i> Nouvel attachement
        </button>
      </div>
    </div>
    <hr class="mb-1" />
    <h5>Liste des attachements pour le contrat: <b><span class="text-primary">{{ contract.ref }}</span></b></h5>
    <div class="row">
      <div class="col-md-12">
        <div class="float-right" style="font-size: 18px">
          <a v-b-popover.hover="`Activer le mode KANBAN`" @click.prevent="showKanbanView" :class="showKanban ? 'activeActionLink' : ''" href=""><i class="fab fa-trello"></i></a>
          <a v-b-popover.hover="`Activer le mode TABLEAU`" @click.prevent="showListView" :class="showTable ? 'activeActionLink' : ''" href=""><i class="fas fa-list ml-2"></i></a>
        </div>
      </div>
    </div>
      <KanbanView v-if="showKanban" :invoices="invoices"/>
      <ListView v-if="showTable" :invoices="invoices"/>

    <new-invoice-modal
      :showModal="showNesInvoiceModal"
      :contract="contract"
      @closeNewInvoiceModal="handleCloseNewInvoiceModalEvent"
    />
  </div>
</template>

<script>
import {
  filterList,
  clone,
  isoDateToEuroDate,
  searchAsEuroDate,
} from "./utilities";
import { debounce } from "./helpers";

import KanbanView from "./components/invoices/list/kanban";
import ListView from "./components/invoices/list/list";
import newInvoiceModal from "./components/newInvoiceModal";

export default {
  name: "Example2",
  components: {
    KanbanView,
    ListView,    
    newInvoiceModal,
  },
  props: {
    invoices: {},
    contract: null,
  },
  data: function() {
    return {
      users: [],
      showNesInvoiceModal: false,
      showKanban : true,
      showTable : false,
      startsWith: "",
      onlineFilter: "",
      statusClass: {
        Active: "text-success",
        Away: "text-warning",
        "Do not disturb": "text-danger",
        Invisible: "text-secondary",
      },
      firstNameAsc: true,
    };
  },
  computed: {
    sortFirstName() {
      return this.firstNameAsc ? "firstName" : "-firstName";
    },
  },
 
  created() {
    this.startWithInput = debounce((e) => {
      this.startsWith = e.target.value;
    }, 300);
  },
  methods: {
    filterList,
    isoDateToEuroDate,
    searchAsEuroDate,
    showKanbanView(){
      this.showKanban = true;
      this.showTable = false;
    },
    showListView(){
      this.showKanban = false;
      this.showTable = true;
    },
    startsWithFilter(value) {
      return value.toLowerCase().startsWith(this.startsWith.toLowerCase());
    },

    
    showNewInvoiceModalClick() {
      // fetch current contract camps
      this.showNesInvoiceModal = true;
    },

    handleCloseNewInvoiceModalEvent() {
      this.showNesInvoiceModal = false;
    },
  },
};
</script>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import invoices from "./invoices";
import { getContractInvoicesListApi } from "@/api/common";
/**
 * Starter component
 */
export default {
  page: {
    title: "Ordonnancement",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, invoices },
  import: {getContractInvoicesListApi},
  data() {
    return {
      title: "Ordonnancement",
      invoices: [],
      contract : null,
      items: [
        {
          text: "Suivi & Gestion",
          href: "/requests",
        },
        {
          text: "Ordonnancement",
          active: true,
        },
      ],
    };
  },

  methods: {
    loadContractInvoices(){
        var contractId = this.$route.params.uid;
        var loader = this.$loading.show();
        getContractInvoicesListApi(contractId)
        .then((res) => {
            loader.hide();
            this.invoices = res.data.invoices
            this.contract = res.data.contract;
        })
        .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
        })
        .finally(() => {
        
        });
    },
  },

  mounted() {
      this.loadContractInvoices();
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <invoices :invoices="invoices" :contract="contract"/>
  </Layout>
</template>
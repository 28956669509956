var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dataset", {
        attrs: {
          "ds-data": _vm.invoices,
          "ds-sortby": _vm.sortBy,
          "ds-search-in": ["ref", "supplier", "prest_type"]
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var ds = ref.ds
              return [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 mb-2 mb-md-0" },
                    [_c("dataset-show")],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("dataset-search", {
                        attrs: {
                          "ds-search-placeholder": "Recherche...",
                          wait: 300
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c(
                        "table",
                        { staticClass: "table table-striped d-md-table" },
                        [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("ID")
                                ]),
                                _vm._l(_vm.cols, function(th, index) {
                                  return _c(
                                    "th",
                                    {
                                      key: th.field,
                                      class: ["sort", th.sort],
                                      on: {
                                        click: function($event) {
                                          return _vm.click($event, index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(" " + _vm._s(th.name) + " "),
                                      _c("i", {
                                        staticClass: "gg-select float-right"
                                      })
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ]),
                          _c("dataset-item", {
                            attrs: { tag: "tbody" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var rowIndex = ref.rowIndex
                                    return [
                                      _c("tr", [
                                        _c("th", { attrs: { scope: "row" } }, [
                                          _vm._v(_vm._s(rowIndex + 1))
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "invoicing.display",
                                                    params: { uid: row.uuid }
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(row.ref) + " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(row.contract.supplier.name)
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(row.contract.ref))
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(row.total_amnt.withCurrency)
                                          )
                                        ]),
                                        _c("td", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                row.status.rendered
                                              )
                                            }
                                          })
                                        ]),
                                        _c("td", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                row.paymentStatus
                                              )
                                            }
                                          })
                                        ])
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-md-row flex-column justify-content-between align-items-center"
                  },
                  [
                    _c("dataset-info", { staticClass: "mb-2 mb-md-0" }),
                    _c("dataset-pager")
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dataset", {
    attrs: { "ds-data": _vm.invoices },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var ds = ref.ds
          return [
            _c("div", { staticClass: "row mb-2" }, [
              _c(
                "div",
                { staticClass: "col-md-6 mb-2 mb-md-0" },
                [_c("dataset-show")],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("dataset-search", {
                    attrs: {
                      "ds-search-placeholder": "Recherche ...",
                      wait: 300
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("dataset-item", {
                    staticClass: "form-row mb-3",
                    staticStyle: {
                      "overflow-y": "auto",
                      "max-height": "400px"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            var rowIndex = ref.rowIndex
                            return [
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "invoicing.display",
                                          params: { uid: row.uuid }
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "card mb-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "card-body pt-3 pb-2 px-3"
                                          },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "card-title text-truncate mb-2",
                                                attrs: {
                                                  title: "Index: " + rowIndex
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  { attrs: { href: "" } },
                                                  [
                                                    _vm._v(
                                                      "#" +
                                                        _vm._s(row.ref) +
                                                        " "
                                                    ),
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          row.status.rendered
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "card-subtitle text-truncate text-muted mt-2 mb-2"
                                              },
                                              [
                                                _vm._v(" Contrat: "),
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(row.contract.ref)
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "card-subtitle text-truncate text-muted mt-2 mb-2"
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Prestataire: "),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        row.contract.supplier
                                                          .prestname
                                                      )
                                                    )
                                                  ])
                                                ]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-right float-right text-primary"
                                                  },
                                                  [
                                                    _vm._v(" Montant Total: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.total_amnt
                                                            .withCurrency
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "noDataFound",
                          fn: function() {
                            return [
                              _c("div", { staticClass: "col-md-12 pt-2" }, [
                                _c("p", { staticClass: "text-center" }, [
                                  _vm._v("Aucune facture n'est trouvée")
                                ])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-md-row flex-column justify-content-between align-items-center"
              },
              [
                _c("dataset-info", { staticClass: "mb-2 mb-md-0" }),
                _c("dataset-pager")
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }